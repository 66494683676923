<template>
<div class="row">
    <section class="mt-2">
        <div class="container pb-2">
            <div class="row justify-content-center">
                <div class="col-md-9">
                    <h3 class="title-precios">¿Quienes somos?</h3>
                </div>
                <div class="col-md-12">
                    <section class="mt-2">
                        <div class="container pt-3 pb-2">
                            <div class="row justify-content-center">
                                <div class="col-md-9" style="font-size:18px;">
                                    TIO TOTO empresa familiar con más de 20 años de trayectoria en el rubro de fotocopias e impresiones digitales.<br>
                                    Nuestro equipo de trabajo brinda una atencion personalizada, buscando superar e innovar día a día ofreciendo el mejor servicio, acompañando como siempre a instituciones y a estudiantes.
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>

</div>
</template>

<script>

export default {
    name: 'file',
    components: {
    },
    data() {},
    mounted() {},
    methods: {},
    computed: {

    }
};
</script>

<style>
</style>
